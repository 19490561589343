/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpDashboardLiteStates($stateProvider): void {
  'ngInject';

  $stateProvider.state('dashboard-lite', {
    parent: 'root',
    url: '/dashboard',
    // Add resolve data as attributes: <acp-dashboard-lite data="$resolve.fetchedData" ... />
    template: '<acp-dashboard-lite />',
    data: {
      permissions: {
        only: 'use_dashboard_lite',
        redirectTo: {
          use_dashboard_lite: 'dashboard'
        }
      }
    },
    resolve: {
      module($ocLazyLoad) {
        'ngInject';

        return import(/* webpackChunkName: "acp.apps.dashboard-lite" */ './index').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}
